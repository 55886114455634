/* Your existing styles */

.footer {
  background-color: #0a2463;
  color: #ffffff;
  padding: 5px 0;
  text-align: center;
}

.footer-links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.footer-links li {
  margin: 0 10px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.footer-links a:hover {
  color: #ff5733;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .footer {
    padding: 5px 0;
  }

  .footer-links {
    flex-wrap: wrap;
    margin-bottom: 12px;
  }

  .footer-links li {
    margin: 0 8px;
  }

  .footer-links a {
    font-size: 14px;
  }
}
