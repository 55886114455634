/* Cart styles */
.cart {
  padding: 16px;
  margin-top: 50px;
}

.cart-title {
  font-size: 24px;
  margin-bottom: 12px;
  color: #333333;
}

.cart-items {
  /* Style cart items */
}

.cart-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;
}

.cart-item:hover {
  background-color: #ffffff;
}

.cart-item-details {
  margin-right: 0;
}

.cart-item-title {
  font-size: 18px;
  margin-bottom: 6px;
  color: #333333;
}

.cart-item-price {
  font-size: 16px;
  color: #ff5733;
}

.cart-total {
  font-size: 20px;
  margin-top: 12px;
  color: #333333;
}

.checkout-button {
  padding: 10px 20px;
  background-color: #ff5733;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-top: 12px;
}

.checkout-button:hover {
  background-color: #e84c00;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .cart {
    padding: 12px;
  }

  .cart-title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .cart-item {
    padding: 12px;
  }

  .cart-item-title {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .cart-item-price {
    font-size: 14px;
  }

  .cart-total {
    font-size: 18px;
    margin-top: 10px;
  }

  .checkout-button {
    padding: 8px 16px;
    font-size: 14px;
    margin-top: 10px;
  }
}
