/* Mixing Contact Form */
.mixing-contact-form {
    background: linear-gradient(90deg, #1a1a1a 0%, #121212 100%);
    border-radius: 10px;
    padding: 30px;
    margin-top: 50px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .mixing-contact-form:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .mixing-contact-form h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .mixing-contact-form p {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .mixing-form-group {
    margin-bottom: 20px;
  }
  
  .mixing-form-group input,
  .mixing-form-group textarea {
    width: 100%;
    padding: 12px;
    background-color: #232323;
    border: none;
    border-radius: 6px;
    color: #ffffff;
    font-size: 16px;
    transition: background-color 0.2s;
  }
  
  .mixing-form-group input::placeholder,
  .mixing-form-group textarea::placeholder {
    color: #777777;
  }
  
  .mixing-form-group input:focus,
  .mixing-form-group textarea:focus {
    background-color: #1a1a1a;
    outline: none;
  }
  
  .mixing-form-group textarea {
    height: 150px;
  }
  
  .mixing-form-group button {
    padding: 15px 30px;
    background-color: #ff5733;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 18px;
  }
  
  .mixing-form-group button:hover {
    background-color: #e84c00;
  }
  