/* Mixing Container */
.mixing-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
  padding: 50px;
  padding-top: 100px;
  background-color: #121212;
  color: #ffffff;
}

/* Mixing Image */
.mixing-image img {
  width: 100%;
  max-width: 280px;
  height: auto;
  margin: 20px auto;
  display: block;
  border-radius: 6%;
}

/* Mixing Tile */
.mixing-tile {
  flex: 1;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  background-color: #1a1a1a;
  text-align: center;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 280px;
}

.mixing-tile:hover {
  transform: translateY(-5px);
}

.mixing-tile h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #8b0000;
}

/* Mixing Quality */
.mixing-quality {
  margin-top: 20px;
}

.mixing-quality h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.mixing-quality ul {
  list-style-type: none;
  padding: 0;
}

.mixing-quality li {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mixing-quality li::before {
  content: '✔️';
  margin-right: 8px;
  color: #ff5733;
}

/* Mixing Pricing */
.mixing-pricing {
  margin-top: 20px;
}

.mixing-pricing h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.mixing-pricing p {
  font-size: 18px;
  margin-bottom: 8px;
}

/* Mixing Form Container */
.contact-form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
  padding: 100px;
  padding-top: 5px;
  background-color: #121212;
  color: #ffffff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .mixing-container {
    flex-direction: column;
    align-items: center;
  }

  .mixing-tile {
    width: 80%;
  }
  .contact-form-container {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .mixing-container {
    padding: 30px;
    padding-top: 80px;
  }

  .mixing-form-container {
    padding-top: 30px;
  }
}
