/* Reset some default styles */
body, h1, h2, h3, p, ul, li {
  margin: 0;
  padding: 0;
}

/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f1f1f1;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header styles */
.header {
  background-color: #292d35;
  padding: 20px 0;
  color: #ffffff;
  text-align: center;
}

.header h1 {
  font-size: 36px;
  margin: 0;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .header h1 {
    font-size: 24px;
  }
}
