/* Reset some default styles */
body, h1, h2, h3, p, ul, li {
    margin: 0;
    padding: 0;
  }
  
  /* Global styles */
  body {
    font-family: Arial, sans-serif;
    background-color: #f1f1f1;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Header styles */
  .header {
    background-color: #292d35;
    padding: 20px 0;
    color: #ffffff;
    text-align: center;
  }
  
  .header h1 {
    font-size: 36px;
    margin: 0;
  }
  
  /* Home styles */
  .beat-store {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 64px); /* Adjust as needed */
    background: linear-gradient(135deg, #12121200, #1f1f1f38);
    color: #000000;
    text-align: center;
    padding: 60px 0;
    margin-top: 20px;
  }
  
  .beat-store-title {
    font-size: 36px;
    margin-bottom: 16px;
  }
  
  .beat-store-description {
    font-size: 18px;
    margin-bottom: 32px;
  }
  
  /* Beat grid styles */
  /* .beat-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    margin-top: 32px;
  } */

  .beat-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    grid-gap: 24px;
    flex: 3;
  }

  .store-layout {
    display: flex;
    width: 100%;
    margin-top: 20px;
  }
  
  .category-list {
    flex: 1;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .category-button {
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #3e92cc;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  
/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .beat-store-title {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .beat-store-description {
    font-size: 16px;
    margin-bottom: 24px;
  }

  .beat-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    margin-top: 24px;
  }
  .store-layout {
    flex-direction: column;
  }

  .category-list {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .beat-store-title {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .beat-store-description {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .beat-grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    margin-top: 16px;
  }
}