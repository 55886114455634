/* Contact Section */
.contact-section {
    width: 100%;
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #12121217;
  }
  
  .contact-container {
    display: flex;
    margin-top: 5px;
  }
  
  .contact-details {
    flex: 3;
    padding: 60px;
    background-color: #011136;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    margin-right: 40px;
    margin-top: 10%;  
    margin-bottom: 10px;
    margin-left: -40%;
  }
  
  .contact-details h2 {
    font-size: 32px;
    margin-bottom: 30px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .contact-icon {
    font-size: 28px;
    margin-right: 15px;
    color: #ff5733;
  }
  
  .social-icons {
    display: flex;
    margin-top: 30px;
  }
  
  .social-icon {
    font-size: 32px;
    margin-right: 25px;
    color: #ffffff;
    transition: color 0.2s ease-in-out;
  }
  
  .social-icon:hover {
    color: #ff5733;
  }
  
  .contact-form {
    flex: 5;
    padding: 60px;
    background-color: #011136;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    margin-left: 40px;
    margin-top: 10%;
    margin-bottom: 10px;
    margin-right: -40%;
  }
  
  .contact-form label {
    display: block;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 30px;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    color: #000000;
  }
  
  .contact-form textarea {
    height: 120px;
  }
  
  .contact-form button {
    padding: 15px 30px;
    background-color: #ff5733;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .contact-form button:hover {
    background-color: #870404;  
  }
  
  /* Media Queries for Tablets */
  @media (max-width: 1024px) {
    .contact-details,
    .contact-form {
      padding: 80px;
      margin-bottom: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  
    .contact-container {
        flex-direction: column; /* Adjusted flex direction for mobile */
       
      }
  }
  
  /* Media Queries for Mobile */
  @media (max-width: 768px) {
    .contact-container {
        flex-direction: column; /* Adjusted flex direction for mobile */
      
      }
     .contact-section{
        padding-top: 40px;
        padding-bottom:  40px;
     }   
    .contact-details,
    .contact-form {
      padding: 25px;
      margin-bottom: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  
    .contact-details h2 {
      font-size: 24px;
      margin-bottom: 15px;
    }
  
    .contact-item {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 15px;
    }
  
    .contact-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  
    .social-icons {
      margin-top: 15px;
    }
  
    .social-icon {
      font-size: 24px;
      margin-right: 15px;
    }
  
    .contact-form label {
      font-size: 18px;
      margin-bottom: 10px;
    }
  
    .contact-form input,
    .contact-form textarea {
      padding: 8px;
      margin-bottom: 15px;
    }
  
    .contact-form textarea {
      height: 100px;
    }
  
    .contact-form button {
      padding: 12px 24px;
    }
  }
  