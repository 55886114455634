/* Reset some default styles */
body, h1, h2, h3, p, ul, li {
  margin: 0;
  padding: 0;
}

/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f1f1f1;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Header styles */
.header {
  background-color: #292d35;
  padding: 20px 0;
  color: #ffffff;
  text-align: center;
  
}

.header h2 {
  font-size: 36px;
}

/* Home styles */


.banner {
  width: 100%;
  max-width: 2000px;
  height: 1020px;
  background-image: url('../assets/images/banner.webp');
  background-size: cover;
  background-position: center;
  text-align: center;
  color: #ffffff;
  padding: 50px;
}

.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 64px); /* Adjust as needed */
  background: linear-gradient(135deg, #ffffff00, #ffffff00);
  color: #000000;
  text-align: center;
  padding: 60px 0;
  margin-top: 20px;
}

.home-title {
  font-size: 36px;
  margin-bottom: 16px;
  
}

.home-description {
  font-size: 18px;
  margin-bottom: 32px;
}

/* Beat grid styles */
/* .beat-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  margin-top: 32px;
} */

.beat-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  grid-gap: 24px;
  flex: 3;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 16px;
  }

  .banner {
    height: 200px;
    padding: 30px;
  }

  .home-title {
    font-size: 28px;
    margin-bottom: 12px;
  }

  .home-description {
    font-size: 16px;
    margin-bottom: 24px;
  }

  .beat-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    margin-top: 24px;
  }
}

@media (max-width: 480px) {
  .home-title {
    font-size: 24px;
    margin-bottom: 8px;
  }


  .banner {
    padding: 20px; 
}

  .home-description {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .beat-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
    margin-top: 16px;
  }
}
