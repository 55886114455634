/* Navbar CSS */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  background-color: #0a2463;
  color: #ffffff;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.navbar:hover {
  background-color: #0a2463;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.logo {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 24px;
  margin-right: 48px;
  transition: opacity 0.3s ease-in-out;
  position: relative;
}

.nav-links.active {
  display: flex;
  flex-direction: column;
  background-color: #0a2463;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.nav-links a {
  text-decoration: none;
  color: #ffffff;
  position: relative;
  transition: color 0.2s ease-in-out;
}

.nav-links a:hover {
  color: #ff5733;
}

.nav-links a::before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ff5733;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

.nav-links a:hover::before {
  transform: scaleX(1);
}

.dropdown-icon {
  display: none; /* Hide the dropdown icon by default */
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.dropdown-icon.active {
  transform: rotate(180deg);
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .navbar {
    padding: 16px;
  }
  .logo{
    font-size: 16px;
  }
  .dropdown-icon {
    display: block;
    margin-right: 24px;
    transition: transform 0.3s ease-in-out;
  }

  .dropdown-icon.active {
    transform: rotate(180deg);
  }

  .nav-links {
    position: absolute;
    top: 64px;
    left: -6%;
    width: 100%;
    align-items: center;
    display: none;
    flex-direction: column;
    gap: 12px;
    background-color: #1e272e;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.3s ease-in-out;
  }

  .navbar.active .nav-links {
    display: flex;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
