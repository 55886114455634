/* IndividualBeat.css */

.individual-beat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  background: url('../assets/images/indi-back.png') center/cover no-repeat; 
  color: #000000;
  position: relative;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)); /* Darker black overlay with 70% opacity */
  z-index: -1;
}

.vertical-line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  background-color: #ff5733;
}

.individual-beat h2 {
  font-size: 36px;
  margin-bottom: 16px;
  z-index: 1;
  position: relative;
}

.individual-beat img {
  max-width: 30%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 32px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5); /* Enhanced shadow */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.individual-beat img:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6); /* Enhanced shadow on hover */
}

.individual-beat p {
  font-size: 24px;
  color: #ff5733;
  margin-bottom: 10px;
}

.description {
  font-size: 18px;
  line-height: 1.8; /* Increased line height for better readability */
  text-align: justify;
  margin-bottom: 10px;
  margin-top: 20px;;
  max-width: 800px;
  color: #ffffff; /* Changed text color */
  padding: 10px; /* Added padding for better spacing */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4); /* Shadow */
}

.button-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.add-to-cart-button,
.buy-now-button {
  padding: 12px 24px;
  font-size: 18px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.add-to-cart-button {
  background-color: #ff5733;
  color: #ffffff;
}

.add-to-cart-button:hover {
  background-color: #d43f00;
}

.buy-now-button {
  background-color: #4caf50;
  color: #ffffff;
}

.buy-now-button:hover {
  background-color: #357a38;
}
/* Original CSS Code */

/* ... Your existing CSS code ... */

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .individual-beat {
    padding: 70px 0;
  }

  .individual-beat h2 {
    font-size: 28px;
    margin-bottom: 12px;
  }

  .individual-beat img {
    max-width: 80%;
    margin-bottom: 24px;
  }

  .individual-beat p {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .description {
    font-size: 16px;
    margin-bottom: 32px;
    margin-top: 16px;
    padding: 16px;
    max-width: 85%;
  }

  .button-container {
    gap: 12px;
  }

  .add-to-cart-button,
  .buy-now-button {
    font-size: 18px;
    padding: 10px 20px;
  }
}
