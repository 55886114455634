.beat-card {
  text-align: center;
  padding: 32px;
  border-radius: 16px;
  background-color: #3e92cc;
  color: #ffffff;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.beat-card:hover {
  transform: translateY(-6px);
  background-color: #011136;
}

.beat-card img {
  width: 100%;
  max-width: 160px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.beat-card h3 {
  font-size: 20px;
  margin: 8px 0;
}

.price {
  font-size: 18px;
  color: #ff5733;
}
/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .beat-card {
    padding: 20px;
  }
  
  .beat-card img {
    max-width: 80px;
    border-radius: 6px;
  }

  .beat-card h3 {
    font-size: 18px;
    margin: 6px 0;
  }

  .price {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .beat-card {
    padding: 8px;
    border-radius: 12px;
    
  }

  .beat-card img {
    max-width: 120px;
    margin-bottom: 12px;
  }

  .beat-card h3 {
    font-size: 16px;
    margin: 4px 0;
  }

  .price {
    font-size: 14px;
  }
}